<template>

    <body>
        <div
            class="py-12 bg-gray-500 h-screen bg-opacity-50  absolute z-30 transition duration-150 ease-in-out   top-0 right-0 bottom-0 left-0">
            <div role="alert" class="container mx-auto w-11/12 md:w-2/3 max-w-lg flex justify-center">
                <div class="relative w-11/12  bg-white  shadow pt-10 pb-8 rounded">
                    <div class="flex flex-col items-center px-4 md:px-12">
                        <img src="../../assets/ob.png" />
                        <p
                            class="text-base sm:text-lg md:text-2xl font-bold md:leading-6 mt-6 text-gray-800 text-center ">
                            We want to know your name</p>

                        <div class="flex items-center mt-4 sm:mt-6 w-full">
                            <div class="w-3/4">
                                <input @change="nameChnaged"
                                    class=" mt-1 appearance-none md:mx-1 w-full rounded border-gray-300  py-2 px-4 text-gray-700 leading-tight "
                                    id="inline-full-name" placeholder="Please Enter Your Name" type="text"
                                    :value="name">
                            </div>
                            <button @click="saveName" v-if="nameEntered"
                                class="text-white ml-4 cursor-pointer w-1/4 focus:outline-none border border-transparent focus:border-gray-800 focus:shadow-outline-gray bg-[#155E75] transition duration-150 ease-in-out hover:bg-[#358da8] h-9 rounded flex items-center justify-center">
                                Let's Go
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </body>
</template>
<script>
import Loading from '@/components/Loading.vue';
import { registerStore } from '@/stores/Register';
import { userStore } from '@/stores/User';
export default {
    name: 'AppOnboarding',
    props: {
       
    },
    setup() {
        const registerStoreIs = registerStore();
        const userStoreIs = userStore();
        return {
            register: registerStoreIs,
            user: userStoreIs
        };
    },

    data() {
        return {
            name: "",
            nameEntered: false
        };
    },
    mounted() {

    },
    methods: {
        nameChnaged(e) {
            this.name = e.target.value
            if (this.name.trim().length > 0) {
                this.nameEntered = true
            }
        },
        saveName() {
            this.loading = true;
            this.register.updateUserName(this.name).then((userIs) => {
                this.user.firebaseUser = userIs;
                let account = { firebaseId: userIs.uid, email: userIs.email, type: "Individual", name: this.name }
                this.user.createAccount(account).then((userAccount) => {
                    let user_details = JSON.parse(JSON.stringify(userIs));
                    user_details.account = userAccount
                    localStorage.user_details = JSON.stringify(user_details);                   
                    this.loading = false;
                    this.$emit("nameSaved")
                })
            })
        }
    },
    components: { Loading }
};
</script>
<template>
    <div>
        
        <template v-if="!isLogin">

            <TopNav v-model="this.store" @sidebarHandler="sidebarHandler">
            </TopNav>
            <div class=" hidden lg:flex " style="width: 200px;" id="mobile-nav">

                <Sidebar v-model="store"></Sidebar>
            </div>
        </template>
        <div :class="'px-2 ' + (isLogin ? '' : 'lg:ml-[200px]')">
            <Onboarding v-if="!isLogin && nameUpdated" @nameSaved="nameSaved"></Onboarding>
            <router-view :key="$route.fullPath"></router-view>

        </div>
    </div>
</template>
<script>
import Help from '@/components/Help.vue';
import Sidebar from '@/components/Sidebar.vue';
import TopNav from '@/components/TopNav.vue';
import Header from '@/components/Header.vue'
import { userStore } from "../stores/User"
import { registerStore } from '@/stores/Register';
import router from '../router'
import Onboarding from '@/components/user/Onboarding.vue';

export default {
    name: "AppLayout",
    setup() {
        const registerStoreIs = registerStore()
        const userStoreIs = userStore();

        return {
            store: userStoreIs,
            rStore: registerStoreIs
        }
    },
    data() {
        return {

        };
    },

    watch: {
        $route(to, from) {
            if (!to.matched.some(record => record.meta.public)) {
                let userDetails = localStorage.user_details;
                if (userDetails != null) { 
                    let currentUser= JSON.parse(userDetails);
                    this.store.firebaseUser = currentUser
                    this.aColor = this.store.avatarDetails[0]
                    this.nameInitials = this.store.avatarDetails[1]
                }
               
                else {
                    router.push("/login")
                }

            }

        }
    },
    computed: {
        isLogin() {
           
            return (this.$route.name === 'login' || this.$route.name === 'validate' || this.$route.name === 'create-contents')
        },
        leftNavigation() {
            return this.leftNavOptions()
        },
        nameUpdated() {
            return this.store.firebaseUser.displayName == null;
        }

    },
    created() {

        let userDetails = localStorage.user_details;
        if (userDetails != null) {
            let userDetailsJson = JSON.parse(userDetails);
            let account = userDetailsJson.account;
            this.store.fetchAccount({ firebaseId: userDetailsJson.uid }).then((accountIs) => {
                if (accountIs != null) {
                    account = accountIs;
                    userDetailsJson.account = accountIs;
                    if (account != null && account.account != null) {
                        this.store.role = account.account.type
                    }
                    localStorage.user_details = JSON.stringify(userDetailsJson);
                }
            })
        }
    },

    methods: {
        sidebarHandler() {
            var sideBar = document.getElementById("mobile-nav");
            sideBar.classList.toggle("hidden");
        },
        leftNavOptions() {
            let nav = this.store.getNavigationOptions();
            return nav;
        },
        nameSaved() {
            // let userDetails = localStorage.user_details;
            // if (userDetails != null) {
            //     let account = JSON.parse(userDetails).account;
            //     if (account != null && account.account != null) {
            //         this.store.role = account.account.type
            //     }
            // }
            this.nameUpdated = true;
        }
    },
    components: { TopNav, Sidebar, Help, Header, Onboarding }
};
</script>

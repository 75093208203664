
import { defineStore } from 'pinia'
import { getAuth, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink, signInWithRedirect, GoogleAuthProvider, updateProfile } from "firebase/auth";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { userStore } from '@/stores/User';
const GOOGLE_VALIDATE_URL = process.env.VUE_APP_GOOGLE_VALIDATE_URL;

// const firebaseConfig = {
//     apiKey: "AIzaSyBZboqxNNhyxY8B9eJci6G_LJP_n6RfqPU",
//     authDomain: "allsellquick-74ca0.firebaseapp.com",
//     projectId: "allsellquick-74ca0",
//     storageBucket: "allsellquick-74ca0.appspot.com",
//     messagingSenderId: "624020343864",
//     appId: "1:624020343864:web:756350afabda6683476acd",
//     measurementId: "G-XTH93TTH8M"
// };



export const registerStore = defineStore('register', {
    state: () => {
        return {
            method: "",
            email: "",
            linkSent: false,
            loading: false,
            redirectDone: false

        }
    },
    actions: {
        initFirebase() {
            
            return new Promise((resolve, reject) => {
                firebase.auth().onAuthStateChanged(user => {
                    if (user) {
                        resolve(user)
                    }
                    else {
                        resolve(null)
                    }
                })
            })
        },
        findUserDetail() {
            let auth = firebase.auth();
            return auth.currentUser;
        },
        updateUserName(name) {
            return new Promise((resolve, reject) => {
                let auth = firebase.auth();
                updateProfile(auth.currentUser, {
                    displayName: name
                }).then(function () {
                    // localStorage.user_details = JSON.stringify(auth.currentUser);
                    resolve(auth.currentUser);
                }, function (error) {
                    reject()
                });
            })

        },
        logoutUser() {
            return new Promise((resolve, reject) => {
                let auth = firebase.auth();
                auth.signOut().then(() => {
                    window.localStorage.removeItem('user_details');
                    resolve()
                })
            })

        },
        findAccessToken() {
            return new Promise((resolve, reject) => {
                let userDetails = window.localStorage.user_details;
                let stsTokenManager = null;
                let userDetailsJson=null;
                if (userDetails != null) {
                    userDetailsJson = JSON.parse(userDetails);
                    stsTokenManager = userDetailsJson.stsTokenManager;

                }
                const auth = firebase.auth();
                if (auth.currentUser) {
                    auth.currentUser.getIdToken(true).then(function (idToken) {
                        if(stsTokenManager!=null){
                            stsTokenManager.accessToken=idToken;
                            userDetailsJson.stsTokenManager=stsTokenManager;
                            window.localStorage.user_details= JSON.stringify(userDetailsJson);
                        }
                        resolve(idToken);
                    })
                }
                else {
                    if (stsTokenManager != null) {
                        resolve(stsTokenManager.accessToken)
                    } else {
                        resolve()
                    }


                }

            })
        },
        signInUsingGoogle() {
            // return new Promise((resolve, reject) => {
            const provider = new GoogleAuthProvider();
            const auth = firebase.auth();
            auth.signInWithRedirect(provider);

            // })


        },
        checkRedirectResults() {
            return new Promise((resolve, reject) => {
                const auth = firebase.auth();
                auth.getRedirectResult().then((result) => {
                    // This gives you a Google Access Token. You can use it to access the Google API.
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    resolve(result.user)

                }).catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // The email of the user's account used.
                    const email = error.customData.email;
                    // The AuthCredential type that was used.
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    // ...
                });
            })
        },

        signInLinkToUser(email) {

            const actionCodeSettings = {
                url: GOOGLE_VALIDATE_URL,
                handleCodeInApp: true
            }
            const auth = firebase.auth();
            sendSignInLinkToEmail(auth, email, actionCodeSettings)
                .then(() => {
                    // The link was successfully sent. Inform the user.
                    // Save the email locally so you don't need to ask the user for it again
                    // if they open the link on the same device.
                    window.localStorage.setItem('emailForSignIn', email);
                    this.loading = false;
                    this.linkSent = true;
                    // ...
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log({ error });
                });

        },
        validateUser(href) {
            return new Promise((resolve, reject) => {
                const auth = firebase.auth();
                console.log({ href });
                if (isSignInWithEmailLink(auth, href)) {
                    let email = window.localStorage.getItem('emailForSignIn');
                    if (!email) {
                        resolve({ "message": "differentBrowser" })
                    }
                    // The client SDK will parse the code from the link for you.
                    signInWithEmailLink(auth, email, href)
                        .then((result) => {
                            resolve(result.user)
                        })
                        .catch((error) => {
                            reject(error)
                        });
                } else {
                    resolve({ "message": "differentBrowser" })
                }
            })



        }
    }

})    
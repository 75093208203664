import Vue from 'vue'
import VueRouter from 'vue-router'



Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    meta: {
      public: true,
    }
  },
  {
    path: '/validate',
    name: 'validate',
    component: () => import('../views/ValidateUser.vue'),
    meta: {
      public: true,
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/templates',
    name: 'template',
    component: () => import('../views/Template.vue')
  },
  {
    path: '/codes',
    name: 'qr',
    component: () => import('../views/Qr.vue')
  },
  {
    path: '/contents',
    name: 'contents',
    component: () => import('../views/Contents.vue')
  },
  {
    path: '/contents/create-content/:id',
    name: 'create-contents',
    component: () => import('../views/CreateNewContent.vue')
  },
  {
    path: '/templates/create-template/:id',
    name: 'create-template',
    component: () => import('../views/CreateNewTemplate.vue')
  },
  {
    path: '/lead-forms',
    name: 'lead-forms',
    component: () => import('../views/Forms.vue')
  },
  {
    path: '/lead-forms/create-form/:id',
    name: 'create-lead-form',
    component: () => import('../views/CreateLeadForm.vue')
  },
  {
    path: '/codes/create-qr/:id/:step',
    name: 'create-qr',
    component: () => import('../views/CreateNewQR.vue')
  },
  {
    path: '/codes/stats/:id',
    name: 'qr-stats',
    component: () => import('../views/QRAnalytics.vue')
  },
  {
    path: '/codes/choose-template/:id',
    name: 'choose-template',
    component: () => import('../views/Qr.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/login",
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

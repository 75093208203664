
import { defineStore } from 'pinia'
import axios from 'axios'
const APP_URL = process.env.VUE_APP_APP_URL;
import { templateStore } from '@/stores/Template'


const navigation = {
    "Team": [{ title: "Dashboard", "route": "/dashboard", icon: "dashboard" },
    { title: "QR Codes", "route": "/codes", icon: "qrcodes" },
    { title: "Templates", "route": "/templates", icon: "templates" },
    { title: "Contents", "route": "/contents", icon: "contents" },
    { title: "Folders", "route": "/folders", icon: "folders" },
    { title: "Campaigns", "route": "/campaigns", icon: "campaigns" },
    ],
    "Individual": [
    { title: "QR Codes", "route": "/codes", icon: "qrcodes" },
    { title: "Templates", "route": "/templates", icon: "templates" },
    { title: "Contents", "route": "/contents", icon: "contents" },
    { title: "Lead Forms", "route": "/lead-forms", icon: "forms" },
    

    ],
    "External": [{ title: "Dashboard", "route": "/dashboard", icon: "dashboard" },
    { title: "QR Codes", "route": "/codes", icon: "qrcodes" }]
}
export const userStore = defineStore('user', {
    state: () => {
        return {
            role: '',
            defaultTemplateOptions: {},
            firebaseUser: {}
        }
    },
    getters: {
        avatarDetails(state) {
            let str = state.firebaseUser.displayName;
            if (str) {
                var hash = 0;
                for (var i = 0; i < str.length; i++) {
                    hash = str.charCodeAt(i) + ((hash << 5) - hash);
                }
                var h = hash % 360;
                let bgcolor = 'hsl(' + h + ', 30%, 80%)';
                let initialsArray = str.split(" ");
                let initials = initialsArray[0].slice(0, 1);
                if (initialsArray.length > 1) {
                    initials = initials + initialsArray[1].slice(0, 1)
                }
                return [bgcolor, initials]
            }
            return []

        }
    },
    actions: {

        createAccount(data) {
            return new Promise((resolve, reject) => {
                axios.post(APP_URL + "/account/CREATE_ACCOUNT", data)
                    .then(response => {
                        if (response.data != null) {
                            this.role = response.data.account.type
                            resolve(response.data)
                        } else {
                            resolve(null)
                        }

                    });

            })
        },
        fetchAccount(data) {
            return new Promise((resolve, reject) => {
                axios.post(APP_URL + "/account/FETCH_ACCOUNT", data)
                    .then(response => {
                        if (response.data != null) {
                            this.role = response.data.account.type
                            if (response.data.account.favtemplate != null) {
                                let templateStroreIs = templateStore();
                                templateStroreIs.getTemplate(response.data.account.favtemplate).then((template) => {
                                    this.defaultTemplateOptions = template;
                                    resolve(response.data)
                                })
                            }
                            else {
                                resolve(response.data)
                            }

                        } else {
                            resolve(null)
                        }

                    });

            })
        },


        getNavigationOptions() {
            // return new Promise((resolve, reject) => {
            //     let options= navigation[this.role];
            //     resolve(options);
            // })
            return navigation[this.role];
        },
    },
})    
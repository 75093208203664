import axios from 'axios'

const APP_URL = process.env.VUE_APP_APP_URL;

var api_request = axios.create({
    baseURL: APP_URL
})
api_request.interceptors.request.use(function (config) {
    if (window.localStorage.user_details != null) {
        let user = JSON.parse(window.localStorage.user_details);
        config.headers['accountid'] = user?.account?.account?.id
        config.headers['userid'] = user?.account?.account?.id
        config.headers['accesstoken'] = user?.stsTokenManager?.accessToken
        config.headers['username'] = user?.displayName
    }

    return config;
}, function (error) {

});


export {
    api_request
} 